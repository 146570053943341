import * as React from "react";
import { highlight, HighlightedCode, Pre, RawCode } from "codehike/code";
import { Loader } from "lucide-react";

import { CopyButton } from "@/components";

type CodeProps = {
  children: string;
  className: string;
};

/**
 * Component to render highlighted code with a copy button and word wrap functionality.
 *
 * @param children The code content to be highlighted.
 * @param className The class name containing language and meta information.
 */
const Code: React.FC<CodeProps> = ({ children, className }) => {
  const [html, setHtml] = React.useState<HighlightedCode | null>(null);

  const getHtml = React.useCallback(async () => {
    const data: RawCode = {
      value: children,
      lang: className.split("-")[1].split(":")[0],
      meta: className.split(":")[1],
    };
    const out = await highlight(data, "dark-plus");
    setHtml(out);
  }, [children, className]);

  React.useEffect(() => {
    getHtml();
  }, [getHtml]);

  if (!html)
    return (
      <div className="flex h-32 w-full items-center justify-center rounded-xl bg-zinc-950/10">
        <Loader className="h-5 w-5 animate-spin" />
      </div>
    );

  const colors = ["#ED6B61", "#F5BE4F", "#61C553"];

  return (
    <div className="rounded-xl bg-[#1E1E1E]">
      <div className="flex h-9 w-full items-center justify-between gap-x-5 bg-[#252526] px-4 text-center text-sm">
        <div className="flex h-full items-center justify-center gap-x-3">
          <div className="flex h-full items-center justify-center gap-x-2">
            {colors.map((color) => (
              <div
                key={color}
                className="h-3 w-3 rounded-full"
                style={{ backgroundColor: color }}
              ></div>
            ))}
          </div>
          <div className="flex h-full w-fit items-center justify-center bg-[#1E1E1E] px-3">
            <span className="font-sans text-xs text-zinc-50">{html.meta}</span>
          </div>
        </div>
        <CopyButton text={html.value} />
      </div>
      <div className="w-full px-4">
        <Pre
          code={html}
          className="!py-4 !text-[clamp(1rem,.4852rem+.3898vw,1.375rem)]"
        />
      </div>
    </div>
  );
};

export default Code;
