import * as React from "react";

import { cn } from "@/lib/cn";

/**
 * Component for the hero background
 */
const HeroBg = React.forwardRef<
  HTMLCanvasElement,
  React.HTMLAttributes<HTMLCanvasElement>
>((props) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext("2d");
    if (ctx) {
      ctx.strokeStyle = "rgba(255, 255, 255, 0.8)";
      ctx.lineWidth = 5;
      ctx.stroke();
    }
  }, []);

  return (
    <canvas
      {...props}
      ref={canvasRef}
      className={cn(
        "absolute -top-44 left-1/2 m-0 h-[550px] w-full -translate-x-1/2 rounded-full bg-[radial-gradient(circle_at_50%_0,#000,_#253efe_80%)] opacity-100 blur-[70px] md:-top-[10%] md:opacity-70 md:blur-[160px] lg:h-[700px] lg:w-[1000px]",
        props.className
      )}
    ></canvas>
  );
});

HeroBg.displayName = "HeroBg";

export default HeroBg;
