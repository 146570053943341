import * as React from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGSAP } from "@gsap/react";

import NavList from "./components/nav-list";
import { cn } from "@/lib/cn";

/**
 * Creates a sticky navbar with a background that changes based on scroll position
 */
const Navbar = () => {
  const [showBg, setShowBg] = React.useState(false);
  const containerRef = React.useRef(null);

  useGSAP(() => {
    const tl = gsap.timeline();

    tl.add(
      gsap.fromTo(
        containerRef.current,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.8,
          delay: 0.3,
          ease: "power1.out",
        }
      )
    );

    ScrollTrigger.create({
      start: "top -100px",
      onEnter: () => setShowBg(true),
      onLeaveBack: () => setShowBg(false),
    });
  }, []);

  return (
    <>
      <div
        className={cn(
          "pointer-events-none fixed left-0 right-0 top-0 z-50 h-[168px] w-full bg-gradient-to-b from-gray-950/50 to-transparent px-[48px] pt-[24px] transition-opacity duration-300 ease-in-out",
          showBg ? "opacity-100" : "opacity-0"
        )}
      />
      <header
        ref={containerRef}
        className="fixed left-1/2 right-0 top-6 z-[999] w-fit -translate-x-1/2 will-change-transform"
      >
        <nav className="relative flex w-full items-center justify-center">
          <NavList showBg={showBg} />
        </nav>
      </header>
    </>
  );
};

export default Navbar;
