import gsap from "gsap";

export const preLoaderAnimations = (
  boxRef: React.RefObject<HTMLDivElement>,
  pathRef: React.RefObject<SVGPathElement>,
  containerRef: React.RefObject<HTMLDivElement>
) => {
  const tl = gsap.timeline();

  const start = "M 0 100 V 50 Q 50 0 100 50 V 100 z";
  const end = "M 0 100 V 0 Q 50 0 100 0 V 100 z";

  tl.add(
    gsap.to(pathRef.current, {
      duration: 0.8,
      delay: 2,
      attr: { d: start },
      ease: "power2.in",
    })
  )
    .add(
      gsap.to(pathRef.current, {
        duration: 0.4,
        attr: { d: end },
        ease: "power2.out",
      })
    )
    .add(
      gsap.to(boxRef.current, {
        duration: 0.5,
        yPercent: 10,
        filter: "blur(20px)",
        opacity: 0,
        ease: "power2.out",
      }),
      "-=0.65"
    )
    .add(
      gsap.to(containerRef.current, {
        opacity: 0,
        ease: "power2.out",
      }),
      "-=0.1"
    );

  return tl;
};
