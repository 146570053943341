import * as React from "react";
import gsap from "gsap";

type MagneticInterface = {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
};

/**
 * Component that creates a magnetic effect on its children.
 */
const Magnetic: React.FC<MagneticInterface> = ({ children }) => {
  const magnetic = React.useRef<any>(null);

  React.useEffect(() => {
    const xTo = gsap.quickTo(magnetic.current, "x", {
      duration: 1,
      ease: "elastic.out(1, 0.3)",
    });
    const yTo = gsap.quickTo(magnetic.current, "y", {
      duration: 1,
      ease: "elastic.out(1, 0.3)",
    });

    if (magnetic.current === null) return;

    magnetic.current.addEventListener("mousemove", (e: any) => {
      const { clientX, clientY } = e;
      const { height, width, left, top } =
        magnetic.current.getBoundingClientRect();
      const x = clientX - (left + width / 2);
      const y = clientY - (top + height / 2);
      xTo(x);
      yTo(y);
    });
    magnetic.current.addEventListener("mouseleave", (e: any) => {
      xTo(0);
      yTo(0);
    });
  }, []);

  return React.cloneElement(children, { ref: magnetic });
};

export default Magnetic;
