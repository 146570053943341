import * as React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

import globe from "./globe.json";
import { navLinks } from "./data";
import { SocialList, LinkList } from "./components";
import { cn } from "@/lib/cn";

/**
 * Component that renders the footer section of the website.
 */
const Footer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ ...props }, ref) => {
  const [currentTime, setCurrentTime] = React.useState(new Date());
  const [isMounted, setIsMounted] = React.useState(false);

  React.useEffect(() => {
    setIsMounted(true);
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const localTime = React.useMemo(() => {
    const time = currentTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return time.replace(/am|pm/i, (match) => match.toUpperCase());
  }, [currentTime]);

  return (
    <footer
      {...props}
      ref={ref}
      className={cn(
        "border-primary-500/50 min-h-[300px] w-full border-t bg-[#100446] py-10",
        props.className
      )}
    >
      <FooterContent isMounted={isMounted} localTime={localTime} />
    </footer>
  );
});

type FooterProps = React.HTMLAttributes<HTMLDivElement> & {
  isMounted: boolean;
  localTime: string;
};

/**
 * Component that renders the footer content.
 *
 * @param isMounted - Whether the component is mounted.
 * @param localTime - The local time.
 */
const FooterContent = ({ isMounted, localTime }: FooterProps) => (
  <>
    <div className="footer-container relative mb-10 flex h-full w-full flex-col items-start justify-between gap-y-12 px-5 sm:max-w-xl md:max-w-3xl lg:max-w-5xl lg:flex-row lg:gap-y-0 xl:max-w-7xl">
      <FooterIntro />
      <FooterLinks />
    </div>
    <FooterBottom isMounted={isMounted} localTime={localTime} />
  </>
);

/**
 * Component that renders the footer intro.
 */
const FooterIntro = () => (
  <div className="flex w-full flex-col items-start justify-start gap-y-3 lg:w-1/2 xl:gap-y-2">
    <h1 className="font-Acorn-Bold text-primary-100 text-3xl tracking-wider">
      Curious?
    </h1>
    <p className="text-primary-300 vs:text-base w-full max-w-xl text-sm tracking-wider lg:max-w-[30rem] xl:text-base">
      I&apos;m always open to new opportunities, collaborations, and projects.
      Whether you have a project in mind or just want to say hello, feel free to
      reach out via email or connect with me on any of my socials.
    </p>
    <SocialList />
  </div>
);

/**
 * Component that renders the footer links.
 */
const FooterLinks = () => (
  <div className="flex w-full flex-wrap items-start justify-start gap-x-20 gap-y-10 lg:w-[60%] lg:justify-end lg:gap-x-20">
    <LinkList title="Navigation" links={navLinks} />
  </div>
);

/**
 * Component that renders the footer bottom.
 *
 * @param isMounted - Whether the component is mounted.
 * @param localTime - The local time.
 */
const FooterBottom = ({ isMounted, localTime }: FooterProps) => (
  <div className="footer-container relative flex w-full flex-col-reverse items-start justify-start gap-y-5 px-5 sm:max-w-xl md:max-w-3xl md:flex-row md:items-end md:justify-evenly md:gap-y-0 lg:max-w-5xl xl:max-w-7xl">
    <div className="flex h-full w-full flex-col items-start justify-between gap-y-1">
      <h1 className="footer-cc-logo">© {new Date().getFullYear()}</h1>
      <h1 className="footer-cc-logo">Tarun Sharma</h1>
    </div>
    <FooterTimeAndGlobe isMounted={isMounted} localTime={localTime} />
  </div>
);

/**
 * Component that renders the footer time and globe.
 *
 * @param isMounted - Whether the component is mounted.
 * @param localTime - The local time.
 */
const FooterTimeAndGlobe = ({ isMounted, localTime }: FooterProps) => (
  <div className="flex h-full flex-col items-start justify-end gap-y-4 sm:gap-y-8 md:w-[40%] md:items-end">
    <div className="text-primary-200 xs:text-base text-sm">
      Local time -{" "}
      <span className="text-primary-300">
        {isMounted ? localTime : "Loading..."}
      </span>
    </div>
    <div className="border-primary-100 divide-primary-100 flex items-center divide-x rounded-md border">
      <div className="overflow-hidden px-2">
        <Player
          src={globe}
          autoplay
          loop
          className="xs:h-10 xs:w-10 h-8 w-8"
          speed={0.2}
        />
      </div>
      <div className="divide-primary-100 divide-y">
        <div className="px-2 py-1">
          <span className="footer-text text-primary-100 font-medium">
            Working Globally
          </span>
        </div>
        <div className="px-2 py-1">
          <span className="footer-text text-primary-200 font-light">
            Available For Work
          </span>
        </div>
      </div>
    </div>
  </div>
);

Footer.displayName = "Footer";

export default Footer;
