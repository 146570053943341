/**
 * Component that renders a dot background.
 */
const DotBackground = () => (
  <div className="relative h-[100px] w-full overflow-hidden rounded-lg">
    <div className="absolute inset-0 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px]" />
  </div>
);

export default DotBackground;
