import * as React from "react";
import { Copy, Check } from "lucide-react";

import { cn } from "@/lib/cn";

type CopyButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  text: string;
};

/**
 * Component that renders a copy button.
 *
 * @param text - The text to be copied.
 */
const CopyButton = React.forwardRef<HTMLButtonElement, CopyButtonProps>(
  ({ text, ...props }, ref) => {
    const [copied, setCopied] = React.useState(false);

    const handleCopy = () => {
      navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 1200);
    };

    return (
      <button
        {...props}
        className={cn(
          "active:cursor-active rounded p-1 text-zinc-200 hover:cursor-pointer hover:bg-gray-400/20",
          props.className
        )}
        aria-label="Copy to clipboard"
        onClick={handleCopy}
        ref={ref}
      >
        {copied ? <Check size={16} /> : <Copy size={16} />}
      </button>
    );
  }
);

CopyButton.displayName = "CopyButton";

export default CopyButton;
