import * as React from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

import { preLoaderAnimations } from "./animations";
import { words } from "./data";

type PreLoaderProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Renders the preloader
 * @param setIsLoading - function to set the isLoading state
 */
const PreLoader: React.FC<PreLoaderProps> = ({ setIsLoading }) => {
  const [currentWord, setCurrentWord] = React.useState("");
  const boxRef = React.useRef<HTMLDivElement>(null);
  const pathRef = React.useRef<SVGPathElement>(null);
  const svgRef = React.useRef<SVGSVGElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  useGSAP(() => {
    const tl = gsap.timeline({
      onComplete: () => {
        setIsLoading(false);
      },
    });

    tl.add(preLoaderAnimations(boxRef, pathRef, containerRef));

    return () => {
      tl.kill();
    };
  }, []);

  React.useEffect(() => {
    const totalDuration = 2000;
    const interval = totalDuration / words.length;

    words.forEach((word, index) => {
      const timer = setTimeout(() => {
        setCurrentWord(word);
      }, index * interval);

      return () => clearTimeout(timer);
    });
  }, []);

  return (
    <div
      ref={containerRef}
      className="relative flex h-[100svh] w-full items-end justify-end overflow-hidden"
    >
      <svg
        ref={svgRef}
        className="absolute inset-0 left-0 top-0 z-40 h-full w-full"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <path
          ref={pathRef}
          className="fill-[#110348]"
          stroke="#110348"
          strokeWidth="2px"
          dur="10s"
          vectorEffect="non-scaling-stroke"
          d="M 0 100 V 100 Q 50 100 100 100 V 100 z"
        />
        <animateMotion dur="10s" repeatCount="indefinite">
          <mpath xlinkHref="#path" />
        </animateMotion>
      </svg>
      <div className="noise-overlay absolute inset-0 !z-0 h-full w-full !opacity-100" />
      <div className="bg-primary-950 flex h-full w-full">
        <div
          ref={boxRef}
          className="xs:gap-x-3 flex w-full items-center justify-center gap-x-2"
        >
          <div className="xs:h-4 xs:w-4 mr-2 inline-block h-3 w-3 rounded-full bg-white" />
          <div className="text-4xl text-white">
            <span className="font-Acorn-Bold xs:text-[4rem] text-[2.5rem] sm:text-[5rem]">
              {currentWord}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
