/**
 * Component that renders a grid background.
 */
const GridBackground = () => (
  <div className="relative h-[100px] w-full overflow-hidden rounded-lg">
    <div className="absolute inset-0 h-full w-full bg-white bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]" />
  </div>
);

export default GridBackground;
