/**
 * Navbar data
 */
export const links = [
  {
    id: 1,
    label: "Work",
    href: "/",
  },
  {
    id: 2,
    label: "About",
    href: "/about",
  },
  {
    id: 3,
    label: "Blogs",
    href: "/blogs",
  },
];
