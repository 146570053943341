/**
 * Component that renders a mask dot background.
 */
const MaskDotBackground = () => (
  <div className="relative h-[100px] w-full overflow-hidden rounded-lg bg-white">
    <div className="absolute inset-0 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]" />
  </div>
);

export default MaskDotBackground;
