import { Mail } from "lucide-react";

import { GithubIcon, LinkedinIcon, XIcon } from "@/components/Icons";

/**
 * List of social links
 */
export const socialLinks = [
  {
    id: 1,
    href: "https://github.com/tarunfy",
    icon: GithubIcon,
    name: "GitHub",
  },
  {
    id: 2,
    href: "https://linkedin.com/in/tarunfy",
    icon: LinkedinIcon,
    name: "LinkedIn",
  },
  {
    id: 3,
    href: "https://x.com/tarunfy",
    icon: XIcon,
    name: "Twitter",
  },
  {
    id: 4,
    href: "mailto:tarunsharma8920@gmail.com",
    icon: Mail,
    name: "Email",
  },
];

/**
 * List of navigation links
 */
export const navLinks = [
  {
    id: 1,
    title: "Work",
    href: "/",
  },
  {
    id: 2,
    title: "About",
    href: "/about",
  },
  {
    id: 3,
    title: "Blogs",
    href: "/blogs",
  },
];

/**
 * List of resources links
 */
//export const resourcesLinks = [
//  {
//    id: 1,
//    title: "Bookmarks",
//    href: "/bookmarks",
//  },
//];
