/**
 * Component for setting up meta tags for SEO.
 */
const Meta = () => {
  const siteUrl =
    process.env.NODE_ENV === "production"
      ? "https://tarunfy.com"
      : "http://localhost:3000";
  return (
    <>
      <meta
        name="description"
        content="The portfolio of Tarun Sharma, Frontend Developer."
      />
      <meta
        name="keywords"
        content="Tarun Sharma, Frontend Developer, Web Developer, React Developer, JavaScript Developer, Full Stack Developer, Frontend Engineer, Creative Web Developer, Web dev, Frontend web dev"
      />
      <meta name="robots" content="index,follow" />
      <meta name="generator" content="Next.js" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content="Tarun Sharma — Frontend Developer" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Tarun Sharma" />
      <meta
        property="og:description"
        content="The portfolio of Tarun Sharma, Frontend Developer."
      />
      <meta
        property="og:image"
        content="https://portfolio-v2-bucket.s3.eu-north-1.amazonaws.com/opengraph-image.png"
      />
      <meta
        property="og:image:url"
        content="https://portfolio-v2-bucket.s3.eu-north-1.amazonaws.com/opengraph-image.png"
      />
      <meta
        property="og:image:secure_url"
        content="https://portfolio-v2-bucket.s3.eu-north-1.amazonaws.com/opengraph-image.png"
      />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content="Tarun Sharma" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@tarunfy" />
      <meta
        property="twitter:image"
        content="https://portfolio-v2-bucket.s3.eu-north-1.amazonaws.com/opengraph-image.png"
      />
      <meta
        property="twitter:description"
        content="The portfolio of Tarun Sharma, Frontend Developer."
      />
      <meta
        property="twitter:title"
        content="Tarun Sharma — Frontend Developer"
      />
      <link
        href="https://api.fontshare.com/v2/css?f[]=satoshi@400,500&display=swap"
        rel="stylesheet"
      />
      <link rel="icon" href="/favicon.ico" />
      <link rel="canonical" href="https://tarunfy.com" />
    </>
  );
};

export default Meta;
