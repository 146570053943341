import * as React from "react";

import { cn } from "@/lib/cn";

type MetallicButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
};

/**
 * Component that renders a metallic button.
 */
const MetallicButton = React.forwardRef<HTMLButtonElement, MetallicButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <div className="ring-primary-500/50 flex w-full items-center justify-center rounded-xl bg-black/20 py-10 ring-1">
        <button
          {...props}
          className={cn(
            "active:cursor-active cursor-pointer rounded-lg border-none bg-[linear-gradient(45deg,#999_5%,#fff_10%,#ccc_30%,#ddd_50%,#ccc_70%,#fff_80%,#999_95%)] px-4 py-1.5 text-base font-medium text-black shadow-md outline-none transition-all duration-700 ease-in-out hover:-translate-y-1",
            props.className
          )}
          ref={ref}
        >
          {children}
        </button>
      </div>
    );
  }
);

MetallicButton.displayName = "MetallicButton";

export default MetallicButton;
