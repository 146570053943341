import gsap from "gsap";
import * as React from "react";

type TransitionContextType = {
  timeline: gsap.core.Timeline;
  setTimeline: React.Dispatch<React.SetStateAction<gsap.core.Timeline>>;
};

export const TransitionContext = React.createContext<TransitionContextType>({
  timeline: gsap.timeline({ paused: true }),
  setTimeline: () => {},
});

export const TransitionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [timeline, setTimeline] = React.useState(() => {
    return gsap.timeline({ paused: true });
  });

  return (
    <TransitionContext.Provider value={{ timeline, setTimeline }}>
      {children}
    </TransitionContext.Provider>
  );
};
