import * as React from "react";
import local from "next/font/local";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import { ReactLenis } from "@studio-freight/react-lenis";

import { Navbar, TailwindIndicator, Footer, PreLoader } from "@/components";
import { TransitionContext } from "@/contexts/TransitionContext";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const acornBold = local({
  src: "../../assets/fonts/acorn-bold.woff",
  display: "swap",
  variable: "--font-acorn-bold",
  preload: true,
});

type LayoutProps = {
  children: React.ReactElement;
  shouldRenderFooter: boolean;
  shouldRenderNavbar: boolean;
};

/**
 * Component that wraps the entire application.
 * It creates the root layout for the application.
 *
 * @param children - The child elements to be rendered within the layout.
 */
const Layout = React.forwardRef<HTMLDivElement, LayoutProps>(
  ({ children, shouldRenderFooter, shouldRenderNavbar, ...props }, ref) => {
    const [displayChildren, setDisplayChildren] = React.useState(children);
    const [isLoading, setIsLoading] = React.useState(true);

    const { timeline } = React.useContext(TransitionContext);

    useGSAP(() => {
      if (children.key !== displayChildren.key && !isLoading) {
        timeline.play().then(() => {
          setDisplayChildren(children);
          window.scrollTo(0, 0);
          timeline.pause().clear();
        });
      }
    }, [children, isLoading]);

    return (
      <div
        ref={ref}
        {...props}
        className={`${acornBold.variable} relative w-full overflow-x-hidden`}
      >
        {isLoading ? (
          <PreLoader setIsLoading={setIsLoading} />
        ) : (
          <>
            {shouldRenderNavbar && <Navbar />}
            <div className="noise-overlay" />
            <ReactLenis root>{displayChildren}</ReactLenis>
            {shouldRenderFooter && <Footer />}
            <TailwindIndicator />
          </>
        )}
      </div>
    );
  }
);

Layout.displayName = "Layout";

export default Layout;
