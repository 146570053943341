export const words = [
  "Hello",
  "Bonjour",
  "Ciao",
  "Olà",
  "स्वागत हे",
  "سلام",
  "やあ",
  "Hallå",
  "Hallo",
];
