import * as React from "react";
import Head from "next/head";

type PageProps = {
  children: React.ReactNode;
  pageTitle: string | string[] | undefined;
};

/**
 * Component that wraps the page content.
 *
 * @param pageTitle Title of the Page
 */
const Page: React.FC<PageProps> = ({ children, pageTitle }) => {
  return (
    <main className="relative min-h-screen w-full max-w-full overflow-x-hidden bg-[#110348]">
      <Head>
        <title>{pageTitle}</title>
      </Head>
      {children}
    </main>
  );
};

export default Page;
