import * as React from "react";
import { MDXRemote, MDXRemoteProps } from "next-mdx-remote";

/**
 * Component that renders MDX content.
 */
const MdxContent: React.FC<MDXRemoteProps> = ({ ...props }) => {
  return <MDXRemote {...props} components={props.components} />;
};

export default MdxContent;
