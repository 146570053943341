import * as React from "react";

import { cn } from "@/lib/cn";
import { links } from "../data";
import NavListItem from "./nav-list-item";
import ShinyButton from "@/components/ShinyButton";

type NavListProps = React.HTMLAttributes<HTMLUListElement> & {
  showBg: boolean;
};

/**
 * Component that renders the navigation list.
 *
 * @param showBg - Whether to show the background or not.
 */
const NavList = React.forwardRef<HTMLUListElement, NavListProps>(
  ({ showBg, ...props }, ref) => {
    return (
      <ul
        {...props}
        className={cn(
          "vs:gap-x-1.5 vs:px-2.5 vs:py-2 vvs:gap-x-1 vvs:px-2 vvs:py-1.5 xs:bg-transparent xs:backdrop-blur-none ring-primary-500/30 xs:ring-0 xs:shadow-none relative flex items-center justify-center rounded-full bg-[#150458]/60 px-1.5 py-1 shadow-[0_12px_30px_#0003] ring-1 backdrop-blur-lg transition duration-200 ease-in-out",
          {
            "xs:!bg-[#150458]/60 xs:!backdrop-blur-lg xs:!ring-primary-500/30 xs:!ring-1 xs:!shadow-[0_12px_30px_#0003] !z-10":
              showBg,
          },
          props.className
        )}
        ref={ref}
      >
        {links.map((l) => (
          <NavListItem key={l.id} listItem={l} />
        ))}
        {showBg && (
          <>
            <div
              className={cn(
                "bg-primary-400 mx-1 h-[20px] w-[0.3px] sm:h-[22px] sm:w-[0.2px] 2xl:h-[26px]"
              )}
            />
            <li>
              <a
                href={"/docs/resume.pdf"}
                download="Resume"
                className="active:cursor-active cursor-pointer rounded-full tracking-widest"
              >
                <ShinyButton
                  rounded="full"
                  showArrow={false}
                  className="vs:px-4 vs:py-2 vs:text-base vvs:px-[0.85rem] vvs:py-[0.4rem] vvs:text-[0.89rem] w-fit px-2.5 py-1 text-sm sm:text-lg 2xl:text-xl"
                >
                  <span className="tracking-widest">Resume</span>
                </ShinyButton>
              </a>
            </li>
          </>
        )}
      </ul>
    );
  }
);

NavList.displayName = "NavList";

export default NavList;
