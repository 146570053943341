import * as React from "react";
import { useRouter as usePathname } from "next/router";
import { motion } from "framer-motion";
import Link from "next/link";

import { cn } from "@/lib/cn";

type NavListItemProps = React.HTMLAttributes<HTMLLIElement> & {
  listItem: {
    id: number;
    label: string;
    href: string;
  };
};

/**
 * Component that renders a single navigation list item.
 *
 * @param listItem - The list item to render.
 */
const NavListItem = React.forwardRef<HTMLLIElement, NavListItemProps>(
  ({ listItem, ...props }, ref) => {
    const pathname = usePathname().pathname;

    const listItemStyles = `
      text-primary-300 active:cursor-active relative flex cursor-pointer items-center justify-center rounded-full vs:px-4 vs:py-1.5 px-3 py-1 vvs:px-[0.85rem] vvs:py-[0.4rem] vvs:text-sm vs:text-base text-[0.8rem] sm:text-lg 2xl:text-xl md:hover:bg-[linear-gradient(135deg,#211cb50d,rgba(231,230,255,0.09))]
    `;

    return (
      <li
        {...props}
        className={cn(listItemStyles, {
          "text-primary-200": pathname === listItem.href,
        })}
        ref={ref}
      >
        <Link scroll={false} href={listItem.href}>
          {pathname === listItem.href && (
            <motion.div
              layoutId="activePill"
              className="bg-primary-100/20 absolute inset-0 z-10"
              style={{ borderRadius: "9999px", originY: "0px" }}
              transition={{ type: "spring", duration: 0.6 }}
            />
          )}
          <button className="active:cursor-active relative z-10 flex cursor-pointer items-center justify-center transition">
            <span>{listItem.label}</span>
          </button>
        </Link>
      </li>
    );
  }
);

NavListItem.displayName = "NavListItem";

export default NavListItem;
