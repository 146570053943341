import * as React from "react";

import Magnetic from "@/components/Magnetic";
import { socialLinks } from "../data";
import { cn } from "@/lib/cn";

/**
 * Component that renders a list of social links inside the footer.
 */
const SocialList = React.forwardRef<
  HTMLUListElement,
  React.HTMLAttributes<HTMLUListElement>
>(({ ...props }, ref) => {
  const socialListStyles = `
  md:from-primary-700 md:via-primary-800 md:to-primary-950 absolute inset-0 scale-0 rounded-full md:bg-gradient-to-bl shadow-xl transition duration-300 ease-in-out md:group-hover:scale-100
  `;

  return (
    <ul
      {...props}
      className={cn(
        "mt-1 flex h-full items-center justify-start gap-x-4",
        props.className
      )}
      ref={ref}
    >
      {socialLinks.map((l) => (
        <Magnetic key={l.id}>
          <li className="active:cursor-active group cursor-pointer rounded-full bg-gradient-to-bl p-2.5">
            <a
              href={l.href}
              aria-label={`Here is my ${l.name} profile`}
              target={l.id === 4 ? "_self" : "_blank"}
              className="active:cursor-active cursor-pointer"
            >
              <div className={socialListStyles} />
              <span className="text-primary-50 relative">
                {<l.icon className="h-[1.6rem] w-[1.6rem]" />}
              </span>
            </a>
          </li>
        </Magnetic>
      ))}
    </ul>
  );
});

SocialList.displayName = "SocialList";

export default SocialList;
