/**
 * Renders a screen size indicator component.
 * Works only in development environment.
 */
const TailwindIndicator = () => {
  if (process.env.NODE_ENV === "production") return null;

  return (
    <div className="sticky bottom-1 left-1 z-[999] flex h-7 w-7 items-center justify-center rounded-full bg-gray-800 p-3 font-mono text-xs text-white">
      <div className="vvs:hidden block">min</div>
      <div className="vs:hidden vvs:block hidden">vvs</div>
      <div className="vxs:hidden vs:block hidden">vs</div>
      <div className="xs:hidden vs:hidden vxs:block hidden">vxs</div>
      <div className="xs:block vs:hidden hidden sm:hidden">xs</div>
      <div className="hidden sm:block md:hidden">sm</div>
      <div className="hidden md:block lg:hidden">md</div>
      <div className="hidden lg:block xl:hidden">lg</div>
      <div className="mid-xl:hidden hidden xl:block">xl</div>
      <div className="mid-xl:block hidden 2xl:hidden">mxl</div>
      <div className="3xl:hidden hidden 2xl:block">2xl</div>
      <div className="3xl:block 4xl:hidden hidden">3xl</div>
      <div className="4xl:block 5xl:hidden hidden">4xl</div>
      <div className="5xl:block hidden">5xl</div>
    </div>
  );
};

export default TailwindIndicator;
