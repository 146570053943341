import * as React from "react";

import { cn } from "@/lib/cn";

/**
 * Component that renders a button with a gradient background.
 */
const Button = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ children, ...props }, ref) => {
  const buttonStyles = `
    active:cursor-active text-primary-100 group-hover:bg-primary-950 bg-primary-900/50 inline-flex cursor-pointer items-center justify-center gap-1 rounded-full p-2 pl-3 text-lg font-light tracking-tight transition-colors duration-300 ease-in-out
    `;

  const arrowStyles = `
    size-5 fill-none stroke-current stroke-[3px] opacity-50 transition-opacity duration-300 ease-in-out group-hover:opacity-100 md:size-6
    `;

  return (
    <button {...props} ref={ref} className={cn(buttonStyles, props.className)}>
      {children}
      <svg viewBox="0 0 24 24" className={arrowStyles}>
        <line
          x1="5"
          y1="12"
          x2="19"
          y2="12"
          className="translate-x-[10px] scale-x-0 transition-transform duration-300 ease-in-out group-hover:translate-x-0 group-hover:scale-x-100"
        />
        <polyline
          points="12 5 19 12 12 19"
          className="-translate-x-2 transition-transform duration-300 ease-in-out group-hover:translate-x-0"
        />
      </svg>
    </button>
  );
});

Button.displayName = "Button";

export default Button;
