import * as React from "react";
import Link from "next/link";

import { cn } from "@/lib/cn";

type LinkListProps = {
  title: string;
  links: { id: number; href: string; title: string }[];
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Reusable component that renders a list of links with a title.
 *
 * @param title - The title to display above the list.
 * @param links - The list of links to render.
 */
const LinkList = React.forwardRef<HTMLDivElement, LinkListProps>(
  ({ title, links, ...props }, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn(
          "flex h-full w-max flex-col items-start justify-start gap-y-2 lg:items-end",
          props.className
        )}
      >
        <h1 className="font-Acorn-Bold text-primary-100 text-xl uppercase">
          {`[ ${title} ]`}
        </h1>
        <ul className="flex flex-col items-start gap-y-2 lg:items-end">
          {links.map((l) => (
            <li key={l.id} className="footer-link">
              <Link
                scroll={false}
                href={l.href}
                className="active:cursor-active cursor-pointer"
              >
                {l.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
);

LinkList.displayName = "LinkList";

export default LinkList;
