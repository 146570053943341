import * as React from "react";
import { motion } from "framer-motion";

import { cn } from "@/lib/cn";

type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  showArrow?: boolean;
  rounded?: "none" | "sm" | "md" | "lg" | "full" | "xl" | "2xl";
};

/**
 * Component that renders a button with a gradient background.
 *
 * @param children The children to be rendered(text content)
 * @param showArrow Weather to show or hide the arrow svg
 * @param rounded The roundedness of the button
 */
const ShinyButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, showArrow = true, rounded = "full", ...props }, ref) => {
    return (
      <motion.button
        {...props}
        ref={ref}
        // @ts-ignore
        initial={{ "--x": "100%", scale: 1 }}
        // @ts-ignore
        animate={{ "--x": "-100%" }}
        whileTap={{ scale: 0.96, transition: { duration: 0.1 } }}
        transition={{
          repeat: Infinity,
          repeatType: "loop",
          repeatDelay: 1,
          type: "spring",
          stiffness: 20,
          damping: 15,
          mass: 2,
          scale: {
            type: "spring",
            stiffness: 10,
            damping: 5,
            mass: 0.1,
          },
        }}
        className={cn(
          "radial-gradient active:cursor-active group relative z-30 cursor-pointer rounded-full px-6 py-2 transition",
          {
            "rounded-none": rounded === "none",
            "rounded-sm": rounded === "sm",
            "rounded-md": rounded === "md",
            "rounded-lg": rounded === "lg",
            "rounded-full": rounded === "full",
            "rounded-xl": rounded === "xl",
            "rounded-2xl": rounded === "2xl",
          },
          props.className
        )}
      >
        <span className="linear-mask relative flex h-full w-full items-center font-light tracking-wide text-neutral-100">
          <span>{children}</span>
          {showArrow && (
            <svg
              viewBox="0 0 24 24"
              className="ml-2 size-6 fill-none stroke-current stroke-[3px] opacity-50 transition-opacity duration-300 ease-in-out group-hover:opacity-100"
            >
              <line
                x1="5"
                y1="12"
                x2="19"
                y2="12"
                className="translate-x-[10px] scale-x-0 transition-transform duration-300 ease-in-out group-hover:translate-x-0 group-hover:scale-x-100"
              />
              <polyline
                points="12 5 19 12 12 19"
                className="-translate-x-2 transition-transform duration-300 ease-in-out group-hover:translate-x-0"
              />
            </svg>
          )}
        </span>
        <span
          className={cn(
            "linear-overlay absolute inset-0 block rounded-full p-px",
            {
              "rounded-none": rounded === "none",
              "rounded-sm": rounded === "sm",
              "rounded-md": rounded === "md",
              "rounded-lg": rounded === "lg",
              "rounded-full": rounded === "full",
              "rounded-xl": rounded === "xl",
              "rounded-2xl": rounded === "2xl",
            }
          )}
        />
      </motion.button>
    );
  }
);

ShinyButton.displayName = "ShinyButton";

export default ShinyButton;
