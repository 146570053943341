import type { AppProps } from "next/app";

import "@/styles/globals.css";
import { Layout } from "@/components";
import { TransitionProvider } from "@/contexts/TransitionContext";

/**
 * Main App Component
 *
 * @param Component - The component to be rendered
 * @param pageProps - The page props
 * @param router - The router object
 */
const App = ({ Component, pageProps, router }: AppProps) => {
  return (
    <TransitionProvider>
      <Layout
        shouldRenderFooter={router.route !== "/404"}
        shouldRenderNavbar={router.route !== "/404"}
      >
        <Component key={router.route} {...pageProps} />
      </Layout>
    </TransitionProvider>
  );
};

export default App;
