import * as React from "react";
import { cn } from "@/lib/cn";

type TooltipProps = React.HTMLAttributes<HTMLDivElement> & {
  text: string;
  position?: "top" | "bottom";
  children: React.ReactNode;
};

/**
 * Component that renders a tooltip.
 *
 * @param text - The text to be displayed in the tooltip.
 * @param position - The position of the tooltip relative to the children.
 * @param children - The children to be displayed in the tooltip.
 */
const Tooltip = React.forwardRef<HTMLDivElement, TooltipProps>(
  ({ text, position = "top", children, ...props }, ref) => {
    const positionClasses = {
      top: "-top-[130%] mb-4",
      bottom: "-bottom-[130%] mt-4",
    };

    return (
      <div
        className="group relative flex w-fit items-center justify-center p-0"
        ref={ref}
        {...props}
      >
        {children}
        <div
          className={cn(
            "bg-primary-700 text-primary-50 ease-in-out-quad ring-primary-500/50 absolute z-[-1] w-max translate-y-1 scale-95 rounded-lg px-5 py-2 text-sm opacity-0 shadow ring-1 transition-all duration-300 group-hover:translate-y-0 group-hover:scale-100 group-hover:opacity-100",
            positionClasses[position]
          )}
        >
          {text}
        </div>
      </div>
    );
  }
);

Tooltip.displayName = "Tooltip";

export default Tooltip;
