import MetallicButton from "../metallic-button";

/**
 * Component that renders a list of MetallicButton components.
 */
const MetallicButtonList = () => {
  return (
    <div className="flex w-full items-center justify-center gap-x-4">
      <MetallicButton className="bg-[linear-gradient(45deg,#d4af37_5%,#debc5b_10%,#e7c97c_30%,#efd69d_50%,#e7c97c_70%,#debc5b_80%,#d4af37_95%)]">
        Download
      </MetallicButton>
      <MetallicButton className="bg-[linear-gradient(45deg,#1e90ff_5%,#00bfff_10%,#87cefa_30%,#87ceeb_50%,#87cefa_70%,#00bfff_80%,#1e90ff_95%)]">
        Download
      </MetallicButton>
      <MetallicButton className="bg-[linear-gradient(45deg,#5eff00_5%,#a3ff47_10%,#99ff50_30%,#37ff00_50%,#99ff50_70%,#a3ff47_80%,#5eff00_95%)]">
        Download
      </MetallicButton>
    </div>
  );
};

export default MetallicButtonList;
